import React from 'react'
import { injectIntl } from 'gatsby-plugin-react-intl'
import styled from 'styled-components'
import { breakpoint, color } from '@configs/utilities'
import SectionWrapper from '@components/ui/wrappers/SectionWrapper'
import { DescriptionDisplay1 } from '@components/ui/typography/Typography'

const FreeAiToolsQuestionsSection = ({ intl }) => {
  return (
    <SectionWrapper sectionBackground={color.white}>
      <Wrapper>
        <Article>
          <Title>{intl.formatMessage({ id: 'free-ai-tools.questions.whatAreOurFreeAiTools.title' })}</Title>
          <TextItem>
            <DescriptionDisplay1>
              {intl.formatMessage({ id: 'free-ai-tools.questions.whatAreOurFreeAiTools.item01' })}
            </DescriptionDisplay1>
          </TextItem>
          <TextItem>
            <DescriptionDisplay1>
              {intl.formatMessage({ id: 'free-ai-tools.questions.whatAreOurFreeAiTools.item02' })}
            </DescriptionDisplay1>
          </TextItem>
          <TextItem>
            <DescriptionDisplay1>
              {intl.formatMessage({ id: 'free-ai-tools.questions.whatAreOurFreeAiTools.item03' })}
            </DescriptionDisplay1>
          </TextItem>
          <TextItem>
            <DescriptionDisplay1>
              {intl.formatMessage({ id: 'free-ai-tools.questions.whatAreOurFreeAiTools.item04' })}
            </DescriptionDisplay1>
          </TextItem>
          <TextItem>
            <DescriptionDisplay1>
              {intl.formatMessage({ id: 'free-ai-tools.questions.whatAreOurFreeAiTools.item05' })}
            </DescriptionDisplay1>
          </TextItem>
        </Article>
        <Article>
          <Title>{intl.formatMessage({ id: 'free-ai-tools.questions.doYouOfferOtherFreeAiTools.title' })}</Title>
          <TextItem>
            <DescriptionDisplay1>
              {intl.formatMessage({ id: 'free-ai-tools.questions.doYouOfferOtherFreeAiTools.item01' })}
            </DescriptionDisplay1>
          </TextItem>
          <TextItem>
            <DescriptionDisplay1>
              {intl.formatMessage({ id: 'free-ai-tools.questions.doYouOfferOtherFreeAiTools.item02' })}
            </DescriptionDisplay1>
          </TextItem>
          <List>
            <TextIcon>📋</TextIcon>
            <Bold>
              {intl.formatMessage({ id: 'free-ai-tools.questions.doYouOfferOtherFreeAiTools.item03.bullet01bold' })},
            </Bold>
            <span>
              {intl.formatMessage({ id: 'free-ai-tools.questions.doYouOfferOtherFreeAiTools.item03.bullet01' })}
            </span>
          </List>
          <List>
            <TextIcon>🔍</TextIcon>
            <Bold>
              {intl.formatMessage({ id: 'free-ai-tools.questions.doYouOfferOtherFreeAiTools.item03.bullet02bold' })}
            </Bold>
            <span>
              {intl.formatMessage({ id: 'free-ai-tools.questions.doYouOfferOtherFreeAiTools.item03.bullet02' })}
            </span>
          </List>
          <List>
            <TextIcon>📊</TextIcon>
            <Bold>
              {intl.formatMessage({ id: 'free-ai-tools.questions.doYouOfferOtherFreeAiTools.item03.bullet03bold' })}
            </Bold>
            <span>
              {intl.formatMessage({ id: 'free-ai-tools.questions.doYouOfferOtherFreeAiTools.item03.bullet03' })}
            </span>
          </List>
          <List>
            <TextIcon>📝</TextIcon>
            <Bold>
              {intl.formatMessage({ id: 'free-ai-tools.questions.doYouOfferOtherFreeAiTools.item03.bullet04bold' })}
            </Bold>
            <span>
              {intl.formatMessage({ id: 'free-ai-tools.questions.doYouOfferOtherFreeAiTools.item03.bullet04' })}
            </span>
          </List>
          <List>
            <TextIcon>✅</TextIcon>
            <Bold>
              {intl.formatMessage({ id: 'free-ai-tools.questions.doYouOfferOtherFreeAiTools.item03.bullet05bold' })},
            </Bold>
            <span>
              {intl.formatMessage({ id: 'free-ai-tools.questions.doYouOfferOtherFreeAiTools.item03.bullet05' })}
            </span>
          </List>
          <List>
            <TextIcon>🎨</TextIcon>
            <Bold>
              {intl.formatMessage({ id: 'free-ai-tools.questions.doYouOfferOtherFreeAiTools.item03.bullet06bold' })}
            </Bold>
            <span>
              {intl.formatMessage({ id: 'free-ai-tools.questions.doYouOfferOtherFreeAiTools.item03.bullet06' })}
            </span>
          </List>
          <List>
            <TextIcon>🤝</TextIcon>
            <Bold>
              {intl.formatMessage({ id: 'free-ai-tools.questions.doYouOfferOtherFreeAiTools.item03.bullet07bold' })}
            </Bold>
            <span>
              {intl.formatMessage({ id: 'free-ai-tools.questions.doYouOfferOtherFreeAiTools.item03.bullet07' })}
            </span>
          </List>
          <TextItem>
            <DescriptionDisplay1>
              {intl.formatMessage({ id: 'free-ai-tools.questions.doYouOfferOtherFreeAiTools.item04' })}
            </DescriptionDisplay1>
          </TextItem>
        </Article>
      </Wrapper>
    </SectionWrapper>
  )
}
export default injectIntl(FreeAiToolsQuestionsSection)

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Article = styled.div`
  max-width: 800px;

  &:first-child {
    margin-bottom: 30px;
  }
`

const Title = styled.div`
  font-size: 36px;
  font-weight: 500;
  margin: 15px 0 40px;
  text-align: center;
`

const List = styled.li`
  font-size: 18px;
  font-weight: 300;
  color: ${color.regularFont};
  line-height: 24px;
  list-style-type: none;
  margin: 5px 0;

  @media screen and (min-width: ${breakpoint.md}) {
    font-size: 20px;
    line-height: 28px;
  }

  @media screen and (min-width: ${breakpoint.xl}) {
    font-size: 24px;
    line-height: 32px;
  }
`

const Bold = styled.span`
  font-weight: 600;
  margin-right: 5px;
  font-size: 18px;
  color: ${color.regularFont};
  line-height: 24px;

  @media screen and (min-width: ${breakpoint.md}) {
    font-size: 20px;
    line-height: 28px;
  }

  @media screen and (min-width: ${breakpoint.xl}) {
    font-size: 24px;
    line-height: 32px;
  }
`

const TextItem = styled.div`
  margin: 20px 0;
  text-align: center;

  @media screen and (min-width: ${breakpoint.md}) {
    text-align: left;
  }
`

const TextIcon = styled.span`
  margin-right: 15px;
`
