import React from 'react'
import { injectIntl } from 'gatsby-plugin-react-intl'
import styled from 'styled-components'
import { color } from '@configs/utilities'
import SectionWrapper from '@components/ui/wrappers/SectionWrapper'
import PageHeader from '@components/ui/typography/PageHeader'

const FreeAiToolsHeaderSection = ({ intl }) => {
  return (
    <SectionWrapper sectionBackground={color.white}>
      <Wrapper>
        <PageHeader
          title={intl.formatMessage({ id: 'free-ai-tools.hero.title' })}
          description={intl.formatMessage({ id: 'free-ai-tools.hero.text' })}
        />
      </Wrapper>
    </SectionWrapper>
  )
}

export default injectIntl(FreeAiToolsHeaderSection)

const Wrapper = styled.div`
  max-width: 840px;
  margin: 0 auto;
`
